import payload_plugin_APjbs5IFuX from "/var/lib/jenkins/workspace/branche-rie-build-stigas_master/frontend/node_modules/.pnpm/@pinia+nuxt@0.9.0_magicast@0.3.5_pinia@2.2.6_typescript@5.6.3_vue@3.5.13_typescript@5.6.3___rollup@4.27.3/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_vp6za35mPC from "/var/lib/jenkins/workspace/branche-rie-build-stigas_master/frontend/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@20.17.6_eslint@8.57.1_ioredis@5.4.1_magicast_6u26htz3zmuc23q7e3fmasb7h4/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_ZvvaLqTU3L from "/var/lib/jenkins/workspace/branche-rie-build-stigas_master/frontend/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@20.17.6_eslint@8.57.1_ioredis@5.4.1_magicast_6u26htz3zmuc23q7e3fmasb7h4/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_zgaNW5iNwn from "/var/lib/jenkins/workspace/branche-rie-build-stigas_master/frontend/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@20.17.6_eslint@8.57.1_ioredis@5.4.1_magicast_6u26htz3zmuc23q7e3fmasb7h4/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_Egq8JgrdCj from "/var/lib/jenkins/workspace/branche-rie-build-stigas_master/frontend/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@20.17.6_eslint@8.57.1_ioredis@5.4.1_magicast_6u26htz3zmuc23q7e3fmasb7h4/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_YHXHBkxx25 from "/var/lib/jenkins/workspace/branche-rie-build-stigas_master/frontend/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@20.17.6_eslint@8.57.1_ioredis@5.4.1_magicast_6u26htz3zmuc23q7e3fmasb7h4/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_c5fzr6GmPl from "/var/lib/jenkins/workspace/branche-rie-build-stigas_master/frontend/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@20.17.6_eslint@8.57.1_ioredis@5.4.1_magicast_6u26htz3zmuc23q7e3fmasb7h4/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_EWGU6l4Ftd from "/var/lib/jenkins/workspace/branche-rie-build-stigas_master/frontend/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@20.17.6_eslint@8.57.1_ioredis@5.4.1_magicast_6u26htz3zmuc23q7e3fmasb7h4/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_W255g2kNj2 from "/var/lib/jenkins/workspace/branche-rie-build-stigas_master/frontend/node_modules/.pnpm/@pinia+nuxt@0.9.0_magicast@0.3.5_pinia@2.2.6_typescript@5.6.3_vue@3.5.13_typescript@5.6.3___rollup@4.27.3/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/var/lib/jenkins/workspace/branche-rie-build-stigas_master/frontend/.nuxt/components.plugin.mjs";
import prefetch_client_bFWx8hbDq6 from "/var/lib/jenkins/workspace/branche-rie-build-stigas_master/frontend/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@20.17.6_eslint@8.57.1_ioredis@5.4.1_magicast_6u26htz3zmuc23q7e3fmasb7h4/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import customElements_client_71mjTDa34z from "/var/lib/jenkins/workspace/branche-rie-build-stigas_master/frontend/plugins/customElements.client.ts";
import sentry_client_shVUlIjFLk from "/var/lib/jenkins/workspace/branche-rie-build-stigas_master/frontend/plugins/sentry.client.ts";
export default [
  payload_plugin_APjbs5IFuX,
  revive_payload_client_vp6za35mPC,
  unhead_ZvvaLqTU3L,
  router_zgaNW5iNwn,
  payload_client_Egq8JgrdCj,
  navigation_repaint_client_YHXHBkxx25,
  check_outdated_build_client_c5fzr6GmPl,
  chunk_reload_client_EWGU6l4Ftd,
  plugin_vue3_W255g2kNj2,
  components_plugin_KR1HBZs4kY,
  prefetch_client_bFWx8hbDq6,
  customElements_client_71mjTDa34z,
  sentry_client_shVUlIjFLk
]